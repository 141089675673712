import * as React from "react";
import "twin.macro";

export default function Descriptive() {
  return (
    <section id="descriptive" tw="container mx-auto py-24 px-6">
      <h2 tw="text-4xl lg:text-5xl font-medium text-center leading-tight">
        Taxi Transport – Ihr Lokaler
        <br /> Umzugsprofi – <span tw="text-primary">Starke Möbelpacker</span>
      </h2>
      <p tw="text-2xl leading-relaxed border-t-4 max-w-4xl mx-auto mt-16 border-primary p-12 shadow-xl">
        Das Umzugsunternehmen Taxi Transport ist Ihr professioneller Partner für
        Ihren Privatumzug und Firmenumzug in Flensburg. Als erfahrene
        Umzugsfirma bieten wir Ihnen einen schnellen Service rund um die
        Planung, Koordinierung und Abwicklung Ihres Umzugsvorhabens. Wir
        übernehmen Ihren gesamten Umzug von der individuellen Beratung im
        Vorfeld über den Transportdienst des Umzugsgutes bis zur Entsorgung
        Ihrer Altmöbel.
      </p>
    </section>
  );
}
