import * as React from "react";
import "twin.macro";

export default function WhyUs() {
  return (
    <section id="whyus" tw="container mx-auto py-24 px-6">
      <h2 tw="text-4xl lg:text-5xl font-medium text-center leading-tight">
        <span tw="text-primary">Drei Gründe</span> für einen <br /> Umzug mit
        Taxi Transport
      </h2>
      <div tw="lg:flex lg:mt-12 max-w-xs lg:max-w-none mx-auto">
        <div tw="lg:w-1/3 lg:pr-6">
          <span tw="text-8xl block max-w-xs text-right -mb-12 text-primary">
            01
          </span>
          <h4 tw="text-xl font-medium mt-4">Flexibel</h4>
          <p tw="max-w-xs mt-3">
            Während andere Umzugsfirmen nicht zeitlich flexibel sind, sind wir
            sofort zur Stelle
          </p>
        </div>
        <div tw="lg:w-1/3 lg:px-6">
          <span tw="text-8xl block max-w-xs text-right -mb-12 text-primary">
            02
          </span>
          <h4 tw="text-xl font-medium mt-4">Schnell</h4>
          <p tw="max-w-xs mt-3">
            Wir reduzieren Ihren eigenen Zeitaufwand für Ihren Umzug auf ein
            Minimum, ganz nach Ihren Wünschen. Sie können Ihr Umzugsgut z.B.
            selbst einpacken (und auspacken) oder uns diese zeitraubenden Dinge
            erledigen lassen.
          </p>
        </div>
        <div tw="lg:w-1/3 lg:pl-6">
          <span tw="text-8xl block max-w-xs text-right -mb-12 text-primary">
            03
          </span>
          <h4 tw="text-xl font-medium mt-4">Günstig</h4>
          <p tw="max-w-xs mt-3">
            Wir haben keine hohen Ausgaben und können somit einen fairen Preis
            für unsere Dienstleistung anbieten
          </p>
        </div>
      </div>
    </section>
  );
}
