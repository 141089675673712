import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { FiPhone } from "react-icons/fi";
import { ImWhatsapp } from "react-icons/im";
import "twin.macro";

export default function Hero() {
  const { site } = useStaticQuery(query);
  const { tel } = site.siteMetadata;

  return (
    <section id="hero" tw="container mx-auto px-6 py-12 items-center lg:flex">
      <div tw="lg:w-1/2 text-center lg:text-left">
        <h1 tw="text-4xl md:text-5xl xl:text-6xl font-medium leading-snug md:leading-tight xl:leading-tight">
          Ihr lokaler 24h <br /> <span tw="text-primary">Umzugsservice</span>{" "}
          für Schleswig-Holstein und Dänemark in Bereitschaft
        </h1>
        <a
          tw="mx-2 text-lg hover:bg-opacity-90 py-3 px-6 mt-8 rounded-md inline-flex items-center font-medium bg-primary text-white"
          href={"tel:" + tel}
        >
          <FiPhone size={24} tw="mr-2" /> {tel}
        </a>
        {/* https://faq.whatsapp.com/android/im-an-android-developer-how-can-i-integrate-whatsapp-with-my-app
        https://fvdm.com/code/note-whatsapp-url-scheme */}
        <a
          tw="mx-2 text-lg hover:bg-opacity-90 py-3 px-6 mt-8 rounded-md inline-flex items-center font-medium bg-primary text-white"
          href={"whatsapp://send?phone=" + tel.replace(/([^0-9])+/g, "")}
        >
          <ImWhatsapp size={24} tw="mr-2" /> {tel}
        </a>
      </div>
      <div tw="lg:w-1/2 py-12 lg:px-12">
        <StaticImage
          tw="rounded-2xl shadow-2xl"
          src="../images/img1.jpg"
          layout="fullWidth"
          alt="LKW vom Umzugsunternehmen Taxi Transport in Flensburg"
        />
      </div>
    </section>
  );
}

const query = graphql`
  query {
    site {
      siteMetadata {
        tel
      }
    }
  }
`;
