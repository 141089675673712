import React from "react";
import tw from "twin.macro";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link } from "gatsby";

export default function Contact() {
  // https://stackoverflow.com/questions/52483260/validate-phone-number-with-yup
  //const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

  const formik = useFormik({
    initialValues: {
      name: "",
      street: "", // spam protection 2: honeypot check in backend
      town: "", // spam protection 1: honeypot for stupid bots
      address: "",
      phone: "",
      email: "",
      message: "",
      privacyCheck: false,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(3, "Muss mindestens 3 Zeichen enthalten")
        .max(30, "Darf nicht mehr als 30 Zeichen enthalten")
        .required("Pflichtfeld"),
      address: Yup.string()
        .min(5, "Muss mindestens 5 Zeichen enthalten")
        .max(50, "Darf nicht mehr als 50 Zeichen enthalten"),
      phone: Yup.string().matches(phoneRegExp, "Telefonnummer nicht gültig"),
      email: Yup.string()
        .email("Keine gültige E-Mail-Adresse")
        .required("Pflichtfeld"),
      message: Yup.string()
        .min(10, "Muss mindestens 10 Zeichen enthalten")
        .max(500, "Darf nicht mehr als 500 Zeichen enthalten")
        .required("Pflichtfeld"),
      privacyCheck: Yup.bool().oneOf([true], "Pflichtfeld"),
    }),
    // https://formik.org/docs/api/formik#onsubmit-values-values-formikbag-formikbag--void--promiseany
    // https://stackoverflow.com/questions/51099439/reactjs-using-formik-to-write-a-form-with-axios
    // https://github.com/kilinkis/react-formik-yup-axios-typescript/blob/master/src/index.tsx
    onSubmit: async (values, { resetForm, setSubmitting, setStatus }) => {
      //alert(JSON.stringify(values, null, 2));

      // spam protection 1
      if (values.town !== "") {
        return;
      }

      try {
        const response = await axios.post("/api/sendmail", values);
        setStatus({ success: true });
        //console.log(response.data.result);
      } catch (error) {
        setStatus({ success: false });
        //console.error(error);
      }

      setSubmitting(false);

      setTimeout(() => {
        resetForm();
      }, 5000);
    },
  });

  const formFieldClass = tw`w-full bg-transparent border-2 border-gray-300 rounded-md focus:border-primary focus:ring-primary focus:ring-opacity-25 focus:ring-4`;

  return (
    <section id="contact" tw="bg-gray-100">
      <div tw="mt-6 max-w-xl px-6 py-24 mx-auto">
        <h2 tw="text-4xl lg:text-5xl font-medium text-center">
          Kontaktformular
        </h2>
        <form onSubmit={formik.handleSubmit}>
          <div tw="mt-12">
            <label>
              <span tw="font-medium mb-1 inline-block">Name</span>
              <br />
              <input
                id="name"
                type="text"
                css={formFieldClass}
                {...formik.getFieldProps("name")}
              />
              {formik.touched.name && formik.errors.name ? (
                <div tw="text-primary">{formik.errors.name}</div>
              ) : null}
            </label>
          </div>
          <div tw="md:flex mt-4">
            <div tw="md:w-1/2 md:pr-3">
              <label>
                <span tw="font-medium mb-1 inline-block">E-Mail</span>
                <br />
                <input
                  id="email"
                  type="email"
                  css={formFieldClass}
                  {...formik.getFieldProps("email")}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div tw="text-primary">{formik.errors.email}</div>
                ) : null}
              </label>
            </div>
            <div tw="md:w-1/2 mt-4 md:mt-0 md:pl-3">
              <label>
                <span tw="font-normal mb-1 inline-block">Telefonnummer</span>
                <br />
                <input
                  id="phone"
                  type="tel"
                  css={formFieldClass}
                  {...formik.getFieldProps("phone")}
                />
                {formik.touched.phone && formik.errors.phone ? (
                  <div tw="text-primary">{formik.errors.phone}</div>
                ) : null}
              </label>
            </div>
          </div>
          <div tw="mt-4 ">
            <label>
              <span tw="font-normal mb-1 inline-block">Adresse</span>
              <br />
              <input
                id="address"
                type="text"
                css={formFieldClass}
                {...formik.getFieldProps("address")}
              />
              {formik.touched.address && formik.errors.address ? (
                <div tw="text-primary">{formik.errors.address}</div>
              ) : null}
            </label>
          </div>
          <div tw="mt-4 hidden">
            <label>
              <span tw="font-medium mb-1 inline-block">Strasse</span>
              <br />
              <input
                id="street"
                type="text"
                css={formFieldClass}
                {...formik.getFieldProps("street")}
              />
            </label>
          </div>
          <div tw="mt-4 hidden">
            <label>
              <span tw="font-medium mb-1 inline-block">Stadt</span>
              <br />
              <input
                id="town"
                type="text"
                css={formFieldClass}
                {...formik.getFieldProps("town")}
              />
            </label>
          </div>
          <div tw="mt-4">
            <label>
              <span tw="font-medium mb-1 inline-block">Ihr Anliegen</span>
              <br />
              <textarea
                id="message"
                rows="6"
                css={formFieldClass}
                {...formik.getFieldProps("message")}
              />
              {formik.touched.message && formik.errors.message ? (
                <div tw="text-primary">{formik.errors.message}</div>
              ) : null}
            </label>
          </div>
          <div tw="mt-4">
            <label>
              <span tw="font-medium mb-1 inline-block">
                <Link tw="hover:text-primary hover:underline" to="/datenschutz">
                  Datenschutzhinweise
                </Link>{" "}
                gelesen und zustimmen:
              </span>
              <input
                id="privacyCheck"
                type="checkbox"
                checked={formik.values.privacyCheck}
                tw="ml-2 border-2 border-gray-300 rounded-md focus:border-primary focus:ring-primary focus:ring-opacity-25 focus:ring-4"
                {...formik.getFieldProps("privacyCheck")}
              />
              {formik.touched.privacyCheck && formik.errors.privacyCheck ? (
                <div tw="text-primary">{formik.errors.privacyCheck}</div>
              ) : null}
            </label>
          </div>
          <button
            disabled={
              formik.isSubmitting || formik.status?.success !== undefined
            }
            type="submit"
            css={[
              tw`w-full p-2 mt-8 text-lg font-medium text-white rounded-md`,
              !formik.isSubmitting &&
                tw`bg-primary focus:outline-none hover:bg-opacity-90`,
              (formik.isSubmitting || !formik.isValid) &&
                tw`bg-gray-700 cursor-not-allowed`,
              formik.status?.success && tw`bg-green-400 cursor-not-allowed`,
              formik.status?.success === false && tw`cursor-not-allowed`,
            ]}
          >
            {!formik.isSubmitting &&
              formik.status?.success === undefined &&
              "Abschicken"}
            {formik.isSubmitting && "Sende ..."}
            {formik.status?.success && "Erfolgreich versendet"}
            {formik.status?.success === false && "Fehler beim Senden"}
          </button>
        </form>
      </div>
    </section>
  );
}
