import * as React from "react";

import SEO from "../components/seo";
import Layout from "../components/layout";
import Hero from "../components/hero";
import Services from "../components/services";
import WhyUs from "../components/whyus";
import NeedHlep from "../components/needhelp";
import Descriptive from "../components/descriptive";
import Contact from "../components/contact";

export default function IndexPage() {
  return (
    <Layout>
      <SEO
        title="Umzüge"
        description="Umzüge in Schleswig-Holstein und Dänemark in Bereitschaft - Express Transporte und Umzug auf Abruf - Haushaltsauflösungen"
      />
      <Hero />
      <Services />
      <WhyUs />
      <NeedHlep />
      <Descriptive />
      <Contact />
    </Layout>
  );
}
