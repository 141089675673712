import * as React from "react";
import { Link } from "gatsby";
import { FiChevronsRight, FiHash, FiInfo } from "react-icons/fi";
import "twin.macro";

export default function NeedHlep() {
  return (
    <section id="needhelp" tw="bg-gray-800 text-gray-400">
      <div id="needhelp" tw="container mx-auto py-24 px-6">
        <h2 tw="text-4xl lg:text-5xl font-medium text-center leading-tight">
          Sie <span tw="text-primary">planen einen Umzug</span>
          <br />
          und benötigen professionelle Hilfe?
        </h2>
        <ul tw="mt-24 max-w-2xl mx-auto text-xl space-y-8">
          <li tw="flex">
            <FiHash tw="text-primary mr-4 flex-shrink-0" size={24} /> Hierfür
            sind wir genau der richtige Ansprechpartner.
          </li>
          <li tw="flex">
            <FiHash tw="text-primary mr-4 flex-shrink-0" size={24} /> Wir
            übernehmen Ihren Umzug, sowie Entrümpelungen und Transporte für ihr
            neues oder altes Heim.
          </li>
          <li tw="flex">
            <FiHash tw="text-primary mr-4 flex-shrink-0" size={24} />
            Kontaktieren Sie uns gerne, um einen Besichtigungstermin zu
            vereinbaren.
          </li>
          <li tw="flex">
            <FiHash tw="text-primary mr-4 flex-shrink-0" size={24} />
            Bei dem Besichtigungstermin schätzen wir den Umfang und
            Arbeitsaufwand Ihres Auftrags ein. Auf dieser Grundlage erstellen
            wir Ihr individuelles Angebot.
          </li>
          <li tw="flex">
            <FiHash tw="text-primary mr-4 flex-shrink-0" size={24} />
            Natürlich haben Sie zusätzlich die Möglichkeit, Fragen zu stellen
            und Einzelheiten zu besprechen.
          </li>
        </ul>
        <h4 tw="text-2xl md:text-4xl text-primary mx-auto max-w-3xl mt-12 text-center">
          <FiInfo size={48} tw="inline-block mb-6" />
          <br />
          Sollten Sie für einen Besichtigungstermin keine Zeit haben, können Sie
          uns alternativ folgende Angaben machen:
        </h4>
        <ul tw="max-w-sm mx-auto mt-12 text-xl space-y-8">
          <li tw="flex items-center">
            <FiChevronsRight tw="text-gray-700 mr-4" size={48} />
            Auflistung bzw. Fotos des Mobiliars
          </li>
          <li tw="flex items-center">
            <FiChevronsRight tw="text-gray-700 mr-4" size={48} />
            <Link to="/#hero">Kontakt per WhatsApp</Link>
          </li>
          <li tw="flex items-center">
            <FiChevronsRight tw="text-gray-700 mr-4" size={48} />
            Anzahl der Umzugskartons
          </li>
          <li tw="flex items-center">
            <FiChevronsRight tw="text-gray-700 mr-4" size={48} />
            Be- und Entladeadresse
          </li>
          <li tw="flex items-center">
            <FiChevronsRight tw="text-gray-700 mr-4" size={48} />
            Stockwerke mit oder ohne Aufzug
          </li>
          <li tw="flex items-center">
            <FiChevronsRight tw="text-gray-700 mr-4" size={48} />
            Ggf. Montagen
          </li>
        </ul>
      </div>
    </section>
  );
}
